import { useEffect, useState } from "react";
import ProductoService from "../../../services/Producto.service";
import { Autocomplete, NativeSelect, TextField } from "@mui/material";
import { toast } from "react-toastify";
import CurrencyInput from "react-currency-input-field";
import TicketsService from "../../../services/Tickets.service";

const CrearPago = ({
  onSumit,
  total,
  metodoPago,
  handleMetodoPago,
  closeModal,
  pagos,
}) => {
  const [productos, setProductos] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [cantidad, setCantidad] = useState(0);
  const [maxPago, setMaxPago] = useState(0);
  const [subtotal, setSubtotal] = useState(0);

  const agregar = async () => {
    console.log(subtotal);
    console.log(cantidad);
    if (cantidad > subtotal) {
      toast.info("La cantidad rebasa el saldo");
      return;
    }
    if (cantidad) {
      await onSumit({ value, cantidad: Number(cantidad) });
    } else {
      await onSumit({ value, cantidad: 0 });
    }
    setCantidad(0);

    closeModal();
  };

  const toMoney = (value) => {
    const money = Number(value);

    if (isNaN(money)) {
      return value;
    }

    return money.toLocaleString("en", {
      style: "currency",
      currency: "MXN",
    });
  };

  const handleKeyDown = (event) => {
    if (event.key !== "Enter") {
      return;
    }
    agregar();
  };

  const formatDate = (date) => {
    const newdate = new Date(date);
    return newdate.toLocaleString();
  };

  const calcularMaxPago = () => {
    let newSubtotal = Number(total);
    pagos?.map((p) => (newSubtotal -= Number(p.precio)));
    setSubtotal((old) => Number(newSubtotal.toFixed(2)));
  };

  useEffect(() => {
    calcularMaxPago();
  }, [total, pagos]);
  return (
    <>
      <div class="modal-body">
        <div class="form-floating">
          <div class="mb-5">
            <div class="small d-flex flex-column align-items-center">
              <div style={{ width: "100%" }}>
                <div
                  class="d-flex"
                  style={{
                    borderBottom: "1px solid black",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <span class="flex-none text-muted">
                    <span class="badge bg-secondary"></span>
                    Total
                  </span>
                  <span>{toMoney(total)}</span>
                </div>
                <div class="d-flex align-items-center mb-3">
                  <span class="w-100">Pagos</span>
                </div>

                {(!pagos || pagos?.length < 1) && (
                  <div>
                    <div class="d-flex justify-content-between">
                      <span
                        class="flex-none text-muted"
                        style={{ width: "120px" }}
                      >
                        <span class="badge bg-secondary"></span>
                        Sin pagos registrados
                      </span>
                    </div>
                  </div>
                )}
                {pagos?.map((pag) => (
                  <div>
                    <div class="d-flex justify-content-between">
                      <span
                        class="flex-none text-muted"
                        style={{ width: "120px" }}
                      >
                        <span class="badge bg-secondary"></span>
                        Monto
                      </span>
                      <span>{toMoney(pag.precio)}</span>
                    </div>
                    <a class="link-muted small" href="javascript:void(0)">
                      {formatDate(pag.fecha)}
                    </a>
                  </div>
                ))}
                <div
                  class="d-flex mt-5"
                  style={{
                    width: "100%",
                    justifyContent: "space-between",
                    borderTop: "1px solid black",
                  }}
                >
                  <span class="flex-none text-muted">
                    <span class="badge bg-secondary"></span>
                    Saldo
                  </span>
                  <span>{toMoney(subtotal)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-check mt-2" style={{ paddingLeft: "0" }}>
          <label className="me-3">Pago:</label>
          <CurrencyInput
            id="input-pago"
            name="input-pago"
            prefix="$"
            max={subtotal}
            placeholder="Ingresa la cantidad"
            allowNegativeValue={false}
            value={cantidad}
            decimalsLimit={2}
            onValueChange={(value, name) => setCantidad(value)}
            style={{
              border: "none",
              backgroundColor: "whitesmoke",
              padding: "0.2rem",
              borderRadius: "4px",
            }}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
      <div class="modal-footer" style={{ justifyContent: "space-between" }}>
        <div>Metodo de pago:</div>
        <div>
          <NativeSelect
            defaultValue={metodoPago}
            onChange={handleMetodoPago}
            className="btn"
            style={{
              padding: "0.5rem",
              backgroundColor: "#9b242c",
              color: "white",
              width: "9rem",
            }}
          >
            <option
              value="Efectivo"
              style={{ color: "black", padding: "0.5rem" }}
            >
              Efectivo
            </option>
            <option
              value="Tarjeta"
              style={{ color: "black", padding: "0.5rem" }}
            >
              Tarjeta
            </option>
            <option
              value="Transferencia"
              style={{ color: "black", padding: "0.5rem" }}
            >
              Transferencia
            </option>
            <option style={{ color: "black", padding: "0.5rem" }} value="DyC">Descuentos y Convenios</option>
            <option style={{ color: "black", padding: "0.5rem" }} value="PG">Programa de Ganchos</option>
          </NativeSelect>
        </div>
        <button type="submit" class="btn btn-primary" onClick={() => agregar()}>
          <svg
            width="18px"
            height="18px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
          <span>Agregar</span>
        </button>
      </div>
    </>
  );
};

export default CrearPago;
