import { useEffect, useState } from "react";
import ProductoService from "../../../services/Producto.service";
import { Autocomplete, TextField } from "@mui/material";
import { toast } from "react-toastify";

const BuscarPoducto = ({ onSumit, closeModal }) => {
  const [productos, setProductos] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [cantidad, setCantidad] = useState(1);
  const [nota, setNota] = useState("");

  const agregar = () => {
    if (!value) {
      toast.info("Selecciona un producto");
      return;
    }

    if (cantidad) {
      onSumit({ value, cantidad, nota });
    } else {
      onSumit({ value, cantidad: 1, nota });
      setCantidad(1);
    }
    closeModal();
    setCantidad(0);
    setValue(null);
  };

  const handleKeyDown = (event) => {
    if (event.key !== "Enter") {
      return;
    }
    agregar();
  };

  const moreCantidad = () => {
    setCantidad((prev) => parseInt(prev) + 1);
  };

  const subCantidad = () => {
    if (cantidad > 1) {
      setCantidad((prev) => parseInt(prev) - 1);
    }
  };

  const handleCantidad = (event) => {
    let newCantidad = event.target.value;

    if (event.target.value.charAt(0) === "0") {
      newCantidad = event.target.value.substring(1, event.target.value.length);
    }
    if (event.target.value.charAt(event.target.value.length - 2) === ".") {
      newCantidad = event.target.value.substring(
        0,
        event.target.value.length - 2
      );
    }

    if (!newCantidad) {
      setCantidad("0");
    } else if (newCantidad) {
      setCantidad(newCantidad);
    }
  };

  const getProductos = async () => {
    try {
      const data = await ProductoService.getAll();
      let productosFormat = [];
      data.map((prod) => {
        productosFormat.push({
          label: prod.nombre,
          id: prod.id,
          categoria: prod.categoria?.tipo,
          precio: Number(
            ((prod.precios[0]?.precio / 100) * (100 - (prod.descuento ?? 0))).toFixed(2)
          ),
          precioOriginal: prod.precios[0]?.precio,
          descuento: prod.descuento,
        });
      });
      console.log(data);
      setProductos(productosFormat);
    } catch (error) {}
  };

  useEffect(() => {
    getProductos();
  }, []);

  return (
    <>
      <div class="modal-body">
        <div class="form-floating">
          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            disablePortal
            id="combo-box-product"
            options={productos}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            fullWidth
            renderInput={(params) => <TextField {...params} label="Producto" />}
          />
          <div class="form-check mt-5" style={{ paddingLeft: "0" }}>
            <label className="me-3">Cantidad:</label>
            <span
              className="btn btn-light"
              style={{ padding: "1rem", border: "1px solid whitesmoke" }}
              onClick={subCantidad}
            >
              -
            </span>
            <span
              style={{
                padding: "1rem",
                border: "1px solid whitesmoke",
                fontSize: "1rem",
              }}
            >
              <input
                name="cantidad"
                type="number"
                value={cantidad}
                onChange={handleCantidad}
                onKeyDown={handleKeyDown}
                style={{ border: "none", width: "5rem", textAlign: "center" }}
              />
            </span>
            <span
              className="btn btn-light"
              style={{ padding: "1rem", border: "1px solid whitesmoke" }}
              onClick={moreCantidad}
            >
              +
            </span>
            <div style={{ paddingTop: "1rem" }}>
              Precio:{" "}
              <span class="text-center fw-bold">${value?.precioOriginal}</span>
            </div>
            {value?.descuento ? (
              <div style={{ paddingTop: "1rem" }}>
                DESCUENTO:{" "}
                <span class="text-center fw-bold">{value?.descuento}%</span>
              </div>
            ) : null}
          </div>
          <div class="mt-2" id="search-container">
            <div class="py-2">
              <label>Categoria:</label>
              {/* <!-- appended item --> */}
              <a href="javascript:void(0);" class="link-normal"></a>
              <a href="javascrip:void(0);" class="text-decoration-none ms-2">
                {value?.categoria}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" onClick={agregar}>
          <svg
            width="18px"
            height="18px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
          <span>Agregar</span>
        </button>
      </div>
    </>
  );
};

export default BuscarPoducto;
