/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../utils/PreloaderElement";
import {
  Box,
  Button,
  Pagination,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
/* Servicios */
import ClienteService from "../../../services/Cliente.service";
import Busqueda from "./Busqueda";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Document from "./Document/Document";

const TablaClientes = () => {
  let today = new Date();
  const [fechaEntrega, setFechaEntrega] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(today)
  );
  const [usuarios, setUsuarios] = useState([]);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [areaExpanded, setAreaExpanded] = useState(-1);
  const navigate = useNavigate();
  const [openEliminar, setOpenEliminar] = useState(false);
  const [noticia, setNoticia] = useState(null);
  const [update, setUpdate] = useState(false);

  const [order, setOrder] = useState(false);
  const [search, setSearch] = useState(false);
  const [filterSearch, setFilterSearch] = useState("");

  const [mostrarPDF, setMostrarPDF] = useState(false);

  const handleGenerarPDF = () => {
    setMostrarPDF(true);
  };

  const handleOpenEliminar = () => {
    setOpenEliminar(true);
  };
  const handleCloseEliminar = () => {
    setOpenEliminar(false);
    setNoticia(null);
  };
  const onUpdate = () => {
    setUpdate(!update);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const deleteCategoria = async () => {
    setLoading(true);
    try {
      const carruselBorrado = await ClienteService.remove(noticia.uuid);
      toast.info("Eliminado");
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
      handleCloseEliminar();
      onUpdate();
    }
  };

  const searchFunctionPage = async (search) => {
    try {
      setLoading(true);
      let searchData = {
        search: search,
        limit: limit,
        offset: limit * page,
      };
      const data = await ClienteService.listByUserSearch(searchData);

      setUsuarios(data.data);
      setTotal(data.total);
      setSearch(true);
    } catch (error) {
      setSearch(false);
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const searchFunction = async (search) => {
    setFilterSearch(search);
    setPage(0);
    if (!search) {
      setSearch(false);
      onUpdate();
    }
    try {
      setLoading(true);
      let searchData = {
        search: search,
        limit: limit,
        offset: limit * page,
      };
      const data = await ClienteService.listByUserSearch(searchData);

      setUsuarios(data.data);
      setTotal(data.total);
      setSearch(true);
    } catch (error) {
      setSearch(false);
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    searchFunctionPage(filterSearch);
    setMostrarPDF(false);
  }, [page, limit, update]);

  const getUsuarios = async () => {
    setLoading(true);
    try {
      const data = await ClienteService.listLimit(limit, limit * page);

      setUsuarios(data.data);
      setTotal(data.total);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const usersFilter = () => {
    if (filter) {
      return usuarios.filter((usuario) =>
        usuario.nombre.toLowerCase().includes(filter.toLowerCase())
      );
    }
    return usuarios;
  };

  const orderAsc = (a, b) => {
    if (a.nombre < b.nombre) {
      return -1;
    }
    if (a.nombre > b.nombre) {
      return 1;
    }
    return 0;
  };

  const orderDesc = (a, b) => {
    if (a.nombre > b.nombre) {
      return -1;
    }
    if (a.nombre < b.nombre) {
      return 1;
    }
    return 0;
  };

  const orderNombre = () => {
    let orderT = usuarios.sort(order ? orderAsc : orderDesc);
    setUsuarios(orderT);
    setOrder(!order);
  };

  const statusCliente = (status) => {
    if (status) {
      return (
        <>
          <span class="d-block text-success">Activo</span>
        </>
      );
    }
    return (
      <>
        <span class="d-block text-info">Sin actividad</span>
      </>
    );
  };

  const handleRowMenu = (indice) => {
    if (indice === areaExpanded) {
      setAreaExpanded(-1);
    } else {
      setAreaExpanded(indice);
    }
  };

  return (
    <>
      {/* main */}
      <main id="middle" className="flex-fill mx-auto">
        {/* <!-- PAGE TITLE --> */}
        <header>
          <div class="row g-1 align-items-center">
            <div class="col">
              <h1 class="h4">Clientes</h1>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb small">
                  <li class="breadcrumb-item">
                    <a href="javascript:void(0);">Clientes</a>
                  </li>
                </ol>
              </nav>
            </div>

            <div class="col-auto">
              <a
                href="javascript:void(0);"
                onClick={() => navigate(`/cliente/nuevo`)}
                role="button"
                class="btn btn-sm btn-primary d-inline-flex align-items-center"
              >
                <svg
                  width="18px"
                  height="18px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
                <span class="d-none d-sm-inline-block ps-2">Nuevo</span>
              </a>
            </div>
          </div>
        </header>

        <div class="section p-0">
          <div class="card-header p-4">
            <div class="row g-3">
              {/* <!-- filter --> */}
              <Busqueda
                searchFunction={searchFunction}
                placeholder="Nombre / numero / uuid cliente"
              />

              {/* <!-- options --> */}
              <div class="order-1 order-md-2 col-md-auto">
                {/* <div
                      class="btn-group w-100"
                      role="group"
                      aria-label="Product options"
                    >
                      <a
                        href="#!"
                        role="button"
                        class="btn btn-sm small btn-secondary"
                      >
                        Export
                      </a>
                    </div> */}
              </div>
            </div>
          </div>

          <div class="card-body pt-1">
            {/* <!-- item list --> */}
            <div class="table-responsive-md">
              {loading ? (
                <div style={{ width: "100%", height: "20rem" }}>
                  <Preloader />
                </div>
              ) : (
                <table
                  class="table table-align-middle"
                  role="grid"
                  aria-describedby="mobile-page-info"
                >
                  <thead>
                    <tr>
                      <th style={{ width: "46px" }}>
                        <div class="form-check">
                          {/* <!-- check all --> */}
                          {/* <input
                              data-checkall-container="#checkall-list"
                              class="form-check-input"
                              type="checkbox"
                              value="1"
                            /> */}
                        </div>
                      </th>
                      <th class="small text-muted">
                        {" "}
                        <a
                          href="javascript:void(0);"
                          class="d-flex link-muted"
                          title="order by price"
                          aria-label="order by inventory"
                          onClick={() => orderNombre()}
                        >
                          <span class="d-flex flex-column lh-1">
                            <svg
                              class="lh-1 text-primary"
                              width="13px"
                              height="13px"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M5 15l7-7 7 7"
                              ></path>
                            </svg>

                            <svg
                              class="lh-1 mt-n1 text-muted"
                              width="13px"
                              height="13px"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 9l-7 7-7-7"
                              ></path>
                            </svg>
                          </span>
                          <span class="ms-2">NOMBRE</span>
                        </a>
                      </th>
                      <th class="small text-muted">FECHA NACIMIENTO</th>
                      <th class="small text-muted" style={{ width: "150px" }}>
                        <a
                          href="javascript:void(0)"
                          class="d-flex link-muted"
                          title="order by price"
                          aria-label="order by inventory"
                        >
                          <span class="d-flex flex-column lh-1"></span>
                          <span class="ms-2">CORREO</span>
                        </a>
                      </th>
                      <th class="small text-muted" style={{ width: "200px" }}>
                        <a
                          href="javascript:void(0)"
                          class="d-flex link-muted"
                          title="order by inventory"
                          aria-label="order by inventory"
                        >
                          <span class="d-flex flex-column lh-1"></span>
                          <span class="ms-2">NÚMERO</span>
                        </a>
                      </th>
                      <th class="small text-muted" style={{ width: "200px" }}>
                        STATUS
                      </th>
                      <th class="small text-muted" style={{ width: "64px" }}>
                        {/* <!-- options --> */}
                      </th>
                    </tr>
                  </thead>
                  <tbody id="checkall-list">
                    {usersFilter().length < 1 && (
                      <tr>
                        <th>
                          {/* <!-- check --> */}
                          <div class="form-check">
                            {/* <input
                              class="form-check-input form-check-input-primary"
                              type="checkbox"
                              value=""
                            /> */}
                          </div>
                        </th>
                        <td class="position-relative">
                          {/* <!-- order --> */}
                          <a
                            href="javascript:void(0);"
                            class="link-normal fw-medium stretched-link d-block"
                          >
                            Sin coincidencias
                          </a>
                          <span class="d-block smaller text-muted">
                            {/* John Doe / john.doe@gmail.com */}
                          </span>
                        </td>
                        <td>
                          {/* <!-- price --> */}
                          <span class="d-block text-success">
                            {/* $149.99 */}
                          </span>
                          <span class="d-block text-muted smaller">
                            {/* 2 items */}
                          </span>
                        </td>
                        <td>
                          {/* <!-- date --> */}
                          <span class="d-block text-muted small">
                            {/* Jan 01 2022, 20:38 */}
                          </span>
                          <span class="d-block text-muted small">
                            {/* 26 days ago */}
                          </span>
                        </td>
                        <td>
                          {/* <!-- status --> */}
                          <span class="d-block text-info">
                            {/* Awayting shipment */}
                          </span>
                          <span class="badge bg-secondary rounded-pill">
                            {/* cash on delivery */}
                          </span>
                        </td>
                        <td class="dropstart">{/* <!-- options --> */}</td>
                      </tr>
                    )}

                    {usersFilter().map((record, index) => {
                      return (
                        <tr key={index}>
                          <th>
                            {/* <!-- check --> */}
                            <div class="form-check">
                              {/* <input
                            class="form-check-input form-check-input-primary"
                            type="checkbox"
                            value=""
                          /> */}
                            </div>
                          </th>
                          <td class="position-relative">
                            {/* <!-- order --> */}
                            <a
                              href="javascript:void(0);"
                              class="link-normal fw-medium stretched-link d-block"
                            >
                              {record.nombre}
                            </a>
                            <span class="d-block smaller text-muted">
                              {record.uuid}
                            </span>
                          </td>
                          <td>
                            {record.fechaNacimiento?
                            <span class="d-block text-muted small">
                              <i
                                className="fa fa-calendar fa-1"
                                aria-hidden="true"
                              />{" "}
                              {String(record.fechaNacimiento).slice(0, 10)}
                            </span>
                            :
                            <span class="d-block text-muted small">
                             N/A                     
                          </span>
                          }
                          </td>
                          <td>
                            {/* <!-- price --> */}
                            <span
                              class="d-block text-success"
                              style={{ textTransform: "none" }}
                            >
                              {record.correo?record.correo:"N/A"}
                            </span>
                            <span class="d-block text-muted smaller">
                              {/* 2 items */}
                            </span>
                          </td>
                          <td>
                            {/* <!-- date --> */}
                            <span class="d-block text-muted small">
                              {record.telefono?record.telefono:"N/A"}
                            </span>
                            <span class="d-block text-muted small">
                              {/* 26 days ago */}
                            </span>
                          </td>
                          <td>
                            {/* <!-- status --> */}
                            {statusCliente(record.activo)}
                          </td>
                          <td class="dropstart">
                            {/* <!-- options --> */}
                            <a
                              class="btn btn-sm btn-light btn-icon btn-ghost text-muted rounded-circle dropdown-toggle"
                              href="javascrip:void(0);"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded={index === areaExpanded}
                              data-bs-offset="0,0"
                              onClick={(event) => {
                                event.preventDefault();
                                handleRowMenu(index);
                              }}
                            >
                              <span class="group-icon">
                                <svg
                                  height="18px"
                                  viewBox="0 0 16 16"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                                  ></path>
                                </svg>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18px"
                                  height="18px"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg>
                              </span>
                            </a>
                            <ul
                              className={
                                index === areaExpanded
                                  ? "dropdown-menu dropdown-menu-clean show"
                                  : "dropdown-menu dropdown-menu-clean"
                              }
                              style={{
                                position: "absolute",
                                inset: "0px 0px auto auto",
                                margin: "0px",
                                transform: "translate(-56px, 11px)",
                              }}
                            >
                              <li class="small px-3 py-2 text-muted">
                                Opciones
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="javascript:void(0);"
                                  onClick={() =>
                                    navigate(`/ticket/nuevo/${record.uuid}`)
                                  }
                                >
                                  <svg
                                    class="text-muted"
                                    width="18px"
                                    height="18px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <polyline points="20 6 9 17 4 12"></polyline>
                                  </svg>
                                  <span>CREAR ORDEN</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="javascript:void(0);"
                                  onClick={() =>
                                    navigate(`/tickets/lista/${record.uuid}`)
                                  }
                                >
                                  <svg
                                    class="text-muted"
                                    width="18px"
                                    height="18px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
                                    ></path>
                                  </svg>
                                  <span>VER ORDENES</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="javascript:void(0)"
                                  onClick={() =>
                                    navigate(`/cliente/editar/${record.uuid}`)
                                  }
                                >
                                  <svg
                                    class="text-muted"
                                    width="18px"
                                    height="18px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path d="M12 20h9"></path>
                                    <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                  </svg>
                                  <span>Editar</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="javascript:void(0)"
                                  onClick={() => {
                                    setNoticia(record);
                                    handleOpenEliminar(record.id);
                                  }}
                                >
                                  <svg
                                    class="text-danger"
                                    width="18px"
                                    height="18px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                    <line
                                      x1="10"
                                      y1="11"
                                      x2="10"
                                      y2="17"
                                    ></line>
                                    <line
                                      x1="14"
                                      y1="11"
                                      x2="14"
                                      y2="17"
                                    ></line>
                                  </svg>
                                  <span>Eliminar</span>
                                </a>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>

            {/* <!-- pagination, selected items --> */}
            <div class="row">
              <div class="col py-3 text-center text-md-start">
                <ul class="list-unstyled m-0 p-0">
                  <li class="list-item">
                    <a
                      class="text-decoration-none"
                      href="javascript:void(0)"
                      role="button"
                    >
                      <PictureAsPdfIcon fontSize="small" />
                      {!mostrarPDF && (
                        <span class="d-sm-inline-block ps-2">
                          <button onClick={handleGenerarPDF} style={{
                            color: "var(--color-dark-variant)", outline: "none",
                            border: "none",
                            backgroundColor: "transparent"
                          }}>
                            GENERAR PDF
                          </button>
                        </span>

                      )}
                      {mostrarPDF && (
                        <span class="d-sm-inline-block ps-2">
                        <PDFDownloadLink
                          document={
                            <Document search={filterSearch} orden={order} />
                          }
                          fileName={`LET_CLIENTES.pdf`}
                          style={{ padding: "0.5rem" }}
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? "Cargando..." : "Descargar lista completa"
                          }
                        </PDFDownloadLink>
                      </span>
                      )}

                      
                    </a>
                  </li>
                </ul>{" "}
              </div>

              <div class="col-md-auto py-3">
                {/* <!-- pagination --> */}
                <TablePagination
                  style={{ color: "var(--color-dark-variant)" }}
                  component="div"
                  classes="recent-orders"
                  labelRowsPerPage="Items por pagina"
                  count={total}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
          </div>
        </div>
        {/* user list */}
      </main>
      {/* /main */}
      {/** ELIMINAR */}
      <Modal
        open={openEliminar}
        onClose={handleCloseEliminar}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="text-uppercase">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Eliminar
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="row g-3 mb-3">
              <div className="col-lg-8">
                <p style={{ color: "#ff7782" }}>{noticia?.nombre}</p>
              </div>
              <div className="col-lg-4">
                <Button
                  variant="outlined"
                  color="error"
                  form="form1"
                  onClick={() => deleteCategoria()}
                  style={{
                    width: "100%",
                    marginBottom: "1rem",
                  }}
                >
                  Eliminar
                </Button>
                <Button
                  variant="outlined"
                  color="info"
                  form="form1"
                  onClick={() => handleCloseEliminar()}
                  style={{ width: "100%" }}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default TablaClientes;
