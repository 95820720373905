/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../utils/PreloaderElement";
import { TablePagination } from "@mui/material";
import { toast } from "react-toastify";

/* Servicios */
import TicketsService from "../../../services/Tickets.service";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import RegistrarEntrega from "./RegistrarEntrega";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import Busqueda from "./Busqueda";
import Filtrar from "./Filtrar";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Document from "./DocumentList/Document";
import { usePrint } from "../../../hooks/usePrint";

const TablaTickets = () => {
  const today = new Date();
  const [tickets, setTickets] = useState([]);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [areaExpanded, setAreaExpanded] = useState(-1);
  const [order, setOrder] = useState(false);
  const [orderfr, setOrderfr] = useState(false);
  const [orderfe, setOrderfe] = useState(false);
  const [openModalEntrega, setOpenModalEntrega] = useState(false);
  const [ticketData, setTicketData] = useState(null);
  const [update, setUpdate] = useState(false);
  const [search, setSearch] = useState(false);
  const [filterSearch, setFilterSearch] = useState("");
  const [filtros, setFiltros] = useState([]);
  const [filtero, setFiltero] = useState({
    estadopago: "",
    estadoEntrega: "",
    fechaIngreso: "",
    fechaSalida: "",
  });

  const [mostrarPDF, setMostrarPDF] = useState(false);

  const [waitingPrint, setWaitingPrint] = useState(false);
  const { printUrl, printIsAuto } = usePrint();

  const handleRowMenu = (indice) => {
    if (indice === areaExpanded) {
      setAreaExpanded(-1);
    } else {
      setAreaExpanded(indice);
    }
  };

  const handleFiltro = (newfiltros, newFiltero) => {
    setFiltros(newfiltros);
    setFiltero(newFiltero);
    onUpdate();
  };

  const handleFilterSearch = (newFilterSearch) => {
    setFilterSearch(newFilterSearch);
  };

  const onUpdate = () => {
    setUpdate(!update);
  };

  const handleGenerarPDF = () => {
    setMostrarPDF(true);
  };

  useEffect(() => {
    searchFunctionPage(filterSearch);
    setMostrarPDF(false);
  }, [page, limit, update]);

  const resendEmailTicket = async (id) => {
    handleRowMenu(-1);
    try {
      let newdata = {
        id: id,
      };
      await TicketsService.resendEmail(newdata);
      toast.success("Reenviado");
    } catch (error) {
      toast.error("Error,No se ha reenviado");
    }
  };
  const handleOpenModalEntrega = (id, estadoEntrega) => {
    let data = { id: id, estadoEntrega: estadoEntrega };
    setTicketData(data);
    setOpenModalEntrega(!openModalEntrega);
  };

  const getTickets = async () => {
    setLoading(true);
    try {
      const data = await TicketsService.list(limit, limit * page);

      setTickets(data.data);
      setTotal(data.total);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatDate = (date) => {
    if (!date) {
      return "00/00/0000";
    }

    const newdate = new Date(date);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      hourCycle: "h12",
    };
    return newdate
      .toLocaleString("es-ES", options)
      .replace(/(\d+)\/(\d+)\/(\d+), (\d+:\d+) ([APM]+)/, "$1-$2-$3 $4 $5");
  };

  const formatDateLocal = (date, tipo) => {
    if (!date) {
      return "00/00/0000";
    }
    const newdate = new Date(date);

    if (tipo !== "Normal") {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        hourCycle: "h12",
      };
      return newdate
        .toLocaleString("es-ES", options)
        .replace(/(\d+)\/(\d+)\/(\d+), (\d+:\d+) ([APM]+)/, "$1-$2-$3 $4 $5");
    }

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    return newdate
      .toLocaleString("es-ES", options)
      .replace(/(\d+)\/(\d+)\/(\d+),/, "$1-$2-$3");
  };

  const orderAsc = (a, b) => {
    if (Number(a.total) < Number(b.total)) {
      return -1;
    }
    if (Number(a.total) > Number(b.total)) {
      return 1;
    }
    return 0;
  };

  const orderDesc = (a, b) => {
    if (Number(a.total) > Number(b.total)) {
      return -1;
    }
    if (Number(a.total) < Number(b.total)) {
      return 1;
    }
    return 0;
  };

  const orderTotal = () => {
    let orderT = tickets.sort(order ? orderAsc : orderDesc);
    setTickets(orderT);
    setOrder(!order);
  };

  const orderFAsc = (a, b) => {
    let fechaa = new Date(a.fecha);
    let fechab = new Date(b.fecha);
    if (fechaa < fechab) {
      return -1;
    }
    if (fechaa > fechab) {
      return 1;
    }
    return 0;
  };

  const orderFDesc = (a, b) => {
    let fechaa = new Date(a.fecha);
    let fechab = new Date(b.fecha);
    if (fechaa > fechab) {
      return -1;
    }
    if (fechaa < fechab) {
      return 1;
    }
    return 0;
  };

  const orderFecha = () => {
    let orderF = tickets.sort(orderfe ? orderFAsc : orderFDesc);
    setTickets(orderF);
    setOrderfe(!orderfe);
  };

  const orderFAscR = (a, b) => {
    let fechaa = new Date(a.fechaRecepcion);
    let fechab = new Date(b.fechaRecepcion);
    if (fechaa < fechab) {
      return -1;
    }
    if (fechaa > fechab) {
      return 1;
    }
    return 0;
  };

  const orderFDescR = (a, b) => {
    let fechaa = new Date(a.fechaRecepcion);
    let fechab = new Date(b.fechaRecepcion);
    if (fechaa > fechab) {
      return -1;
    }
    if (fechaa < fechab) {
      return 1;
    }
    return 0;
  };

  const orderFechaRecepcion = () => {
    let orderF = tickets.sort(orderfr ? orderFAscR : orderFDescR);
    setTickets(orderF);
    setOrderfr(!orderfr);
  };

  const registrarEntrega = async (cantidad) => {
    setAreaExpanded(-1);
    setLoading(true);
    try {
      if (!ticketData) {
        throw "Error";
      }
      let data = {
        uuid: ticketData.id,
        estadoEntrega: cantidad.selectedValue,
        notaEntrega: cantidad.nota,
        responsableEntrega: cantidad.responsableEntrega,
        contrasena: cantidad.contrasena,
        fechaSalida: today.toISOString(),
      };

      if (!data.responsableEntrega) {
        toast.error("No has seleccionado un responsable");
        return;
      }
      await TicketsService.update(data);
      toast.success("Entrega resgistrada");
      setTicketData(null);
      onUpdate();
    } catch (error) {
      toast.error(error.error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const usersFilter = () => {
    if (filter) {
      return tickets.filter((usuario) =>
        usuario.cliente.nombre.toLowerCase().includes(filter.toLowerCase())
      );
    }
    return tickets;
  };

  const searchFunctionPage = async (search) => {
    try {
      setLoading(true);
      let searchData = {
        search: search,
        limit: limit,
        offset: limit * page,
        filters: filtero,
      };
      console.log("Limit:", limit, "Page:", page, "Offset:", limit * page);

      const data = await TicketsService.listByClienteSearch(searchData);

      console.log("tickets:", data.data);
      console.log("total:", data.total);

      setTickets(data.data);
      setTotal(data.total);
      setSearch(true);
    } catch (error) {
      setSearch(false);
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const searchFunction = async (search) => {
    setFilterSearch(search);
    setPage(0);
    try {
      setLoading(true);
      let searchData = {
        search: search,
        limit: limit,
        offset: limit * page,
        filters: filtero,
      };
      console.log("Limit:", limit, "Page:", page, "Offset:", limit * page);

      const data = await TicketsService.listByClienteSearch(searchData);

      setTickets(data.data);
      setTotal(data.total);
      setSearch(true);
    } catch (error) {
      setSearch(false);
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const enviarImpresion = async (uuid) => {
    if (waitingPrint) {
      return;
    }

    handleRowMenu(-1);
    setWaitingPrint(true);
    try {
      let newTicket = { id: uuid };
      const res = await TicketsService.resendPrint(newTicket);
      printUrl(res.url);
    } catch (error) {
      toast.error("Error. No enviado!");
    } finally {
      setWaitingPrint(false);
    }
  };

  return (
    <>
      {/* main */}
      <main id="middle" className="flex-fill mx-auto">
        {/* <!-- PAGE TITLE --> */}
        <header>
          <div class="row g-1 align-items-center">
            <div class="col">
              <h1 class="h4">Ordenes de servicio</h1>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb small">
                  <li class="breadcrumb-item">
                    <a href="javascript:void(0);">Historial</a>
                  </li>
                </ol>
              </nav>
            </div>

            <div class="col-auto">
              <a
                href="javascript:void(0);"
                role="button"
                class="btn btn-sm btn-primary d-inline-flex align-items-center"
                onClick={() => navigate("/ticket/crear/")}
              >
                <svg
                  width="18px"
                  height="18px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
                <span class="d-none d-sm-inline-block ps-2">Crear orden</span>
              </a>
            </div>
          </div>
        </header>

        <div class="section p-0 text-uppercase">
          {/* <div class="card-header p-4">
            <div class="row g-3">
              <div class="order-2 order-md-1 col">
                <form
                  method="get"
                  class="position-relative d-flex align-items-center"
                >
                  <svg
                    class="z-index-1 position-absolute start-0 ms-3 text-primary"
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>

                  <div class="dropdown w-100">
                    <input
                      type="text"
                      class="dropdown-toggle form-control form-control-sm border-0 shadow-none ps-5 bg-gray-100"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      placeholder="Nombre"
                      value={filter}
                      onChange={(e) => setFilter(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <div class="order-1 order-md-2 col-md-auto">
                <div
                      class="btn-group w-100"
                      role="group"
                      aria-label="Product options"
                    >
                      <a
                        href="#!"
                        role="button"
                        class="btn btn-sm small btn-secondary"
                      >
                        Export
                      </a>
                    </div>
              </div>
            </div>
          </div> */}

          <Busqueda
            searchFunction={searchFunction}
            placeholder="Nombre / uuid orden / uuid cliente"
          />

          <Filtrar
            filtroValue={filtero}
            filtros={filtros}
            handleFiltros={handleFiltro}
          />

          <div class="card-body pt-1">
            {/* <!-- item list --> */}
            <div class="table-responsive-md" style={{ overflow: "auto" }}>
              {loading ? (
                <div style={{ width: "100%", height: "20rem" }}>
                  <Preloader />
                </div>
              ) : (
                <table
                  class="table table-align-middle"
                  role="grid"
                  aria-describedby="mobile-page-info"
                >
                  <thead>
                    <tr>
                      <th style={{ width: "46px" }}>
                        <div class="form-check">
                          {/* <!-- check all --> */}
                          {/* <input
                              data-checkall-container="#checkall-list"
                              class="form-check-input"
                              type="checkbox"
                              value="1"
                            /> */}
                        </div>
                      </th>
                      <th class="small text-muted">Orden</th>
                      <th class="small text-muted" style={{ width: "320px" }}>
                        <span class="ms-2">CLIENTE</span>
                      </th>
                      <th class="small text-muted" style={{ width: "150px" }}>
                        <a
                          href="javascript:void(0);"
                          class="d-flex link-muted"
                          title="order by price"
                          aria-label="order by inventory"
                          onClick={() => orderTotal()}
                        >
                          <span class="d-flex flex-column lh-1">
                            <svg
                              class="lh-1 text-primary"
                              width="13px"
                              height="13px"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M5 15l7-7 7 7"
                              ></path>
                            </svg>

                            <svg
                              class="lh-1 mt-n1 text-muted"
                              width="13px"
                              height="13px"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 9l-7 7-7-7"
                              ></path>
                            </svg>
                          </span>
                          <span class="ms-2">TOTAL</span>
                        </a>
                      </th>

                      <th class="small text-muted" style={{ width: "200px" }}>
                        <a
                          href="javascript:void(0);"
                          class="d-flex link-muted"
                          title="order by inventory"
                          aria-label="order by inventory"
                          onClick={() => orderFechaRecepcion()}
                        >
                          <span class="d-flex flex-column lh-1">
                            <svg
                              class="lh-1 text-primary"
                              width="13px"
                              height="13px"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M5 15l7-7 7 7"
                              ></path>
                            </svg>

                            <svg
                              class="lh-1 mt-n1 text-muted"
                              width="13px"
                              height="13px"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 9l-7 7-7-7"
                              ></path>
                            </svg>
                          </span>
                          <span class="ms-2">Fecha recepción</span>
                        </a>
                      </th>
                      <th class="small text-muted" style={{ width: "200px" }}>
                        <a
                          href="javascript:void(0);"
                          class="d-flex link-muted"
                          title="order by inventory"
                          aria-label="order by inventory"
                          onClick={() => orderFecha()}
                        >
                          <span class="d-flex flex-column lh-1">
                            <svg
                              class="lh-1 text-primary"
                              width="13px"
                              height="13px"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M5 15l7-7 7 7"
                              ></path>
                            </svg>

                            <svg
                              class="lh-1 mt-n1 text-muted"
                              width="13px"
                              height="13px"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 9l-7 7-7-7"
                              ></path>
                            </svg>
                          </span>
                          <span class="ms-2">Fecha entrega</span>
                        </a>
                      </th>
                      <th class="small text-muted" style={{ width: "100px" }}>
                        Estado pago
                      </th>
                      <th class="small text-muted" style={{ width: "200px" }}>
                        Estado entrega
                      </th>
                      <th class="small text-muted" style={{ width: "64px" }}>
                        {/* <!-- options --> */}
                      </th>
                    </tr>
                  </thead>
                  <tbody id="checkall-list">
                    {usersFilter().length < 1 && (
                      <tr>
                        <th>
                          {/* <!-- check --> */}
                          <div class="form-check">
                            {/* <input
                              class="form-check-input form-check-input-primary"
                              type="checkbox"
                              value=""
                            /> */}
                          </div>
                        </th>
                        <td class="position-relative">
                          {/* <!-- order --> */}
                          <a
                            href="javascript:void(0);"
                            class="link-normal fw-medium stretched-link d-block"
                          >
                            Sin coincidencias
                          </a>
                          <span class="d-block smaller text-muted">
                            {/* John Doe / john.doe@gmail.com */}
                          </span>
                        </td>
                        <td>
                          {/* <!-- price --> */}
                          <span class="d-block text-success">
                            {/* $149.99 */}
                          </span>
                          <span class="d-block text-muted smaller">
                            {/* 2 items */}
                          </span>
                        </td>
                        <td>
                          {/* <!-- date --> */}
                          <span class="d-block text-muted small">
                            {/* Jan 01 2022, 20:38 */}
                          </span>
                          <span class="d-block text-muted small">
                            {/* 26 days ago */}
                          </span>
                        </td>
                        <td>
                          {/* <!-- status --> */}
                          <span class="d-block text-info">
                            {/* Awayting shipment */}
                          </span>
                          <span class="badge bg-secondary rounded-pill">
                            {/* cash on delivery */}
                          </span>
                        </td>
                        <td class="dropstart">{/* <!-- options --> */}</td>
                      </tr>
                    )}

                    {usersFilter().map((record, index) => {
                      return (
                        <tr key={index}>
                          <th>
                            {/* <!-- check --> */}
                            <div class="form-check">
                              {/* <input
                                  class="form-check-input form-check-input-primary"
                                  type="checkbox"
                                  value=""
                                /> */}
                            </div>
                          </th>
                          <td class="position-relative">
                            {/* <!-- order --> */}
                            <span
                              class="d-block smaller btn-link"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(`/ticket/detalles/${record.uuid}`)
                              }
                            >
                              {record.uuid}
                            </span>
                          </td>
                          <td>
                            {record.cliente?.nombre}
                            <span class="d-block smaller text-muted">
                              {"RECEPCIÓN: " +
                                record.responsableservicio?.nombre}
                            </span>
                          </td>
                          <td>
                            {/* <!-- price --> */}
                            <span class="d-block text-success">
                              ${record.total}
                            </span>
                            <span class="d-block text-muted smaller">
                              {/* 2 items */}
                            </span>
                          </td>
                          <td>
                            {/* <!-- date --> */}
                            <span class="d-block text-muted small">
                              {formatDate(record.fechaRecepcion)}
                            </span>
                            <span class="d-block text-muted small">
                              {/* 26 days ago */}
                            </span>
                          </td>
                          <td>
                            {/* <!-- date --> */}
                            <span class="d-block text-muted small">
                              {formatDateLocal(
                                record.fecha,
                                record.tiposervicio
                              )}
                            </span>
                            <span class="d-block text-muted small">
                              {/* 26 days ago */}
                            </span>
                          </td>
                          <td>
                            {/* <!-- status --> */}
                            <span class="d-block text-info">
                              {record.metodopago}
                            </span>
                            <span
                              className={
                                record.estadopago !== "Liquidada"
                                  ? record.estadopago !== "Pendiente"
                                    ? record.estadopago !== "Gasto"
                                      ? "badge bg-secondary rounded-pill"
                                      : "badge rounded-pill bg-danger"
                                    : "badge rounded-pill"
                                  : "badge bg-success rounded-pill"
                              }
                              style={
                                record.estadopago === "Pendiente"
                                  ? { backgroundColor: "#ffbb00" }
                                  : null
                              }
                            >
                              {record.estadopago}
                            </span>
                          </td>
                          <td>
                            {/* <!-- status --> */}
                            <span class="d-block text-info"></span>
                            <span
                              style={
                                record.estadoEntrega === "Entregada"
                                  ? {
                                    color: "#9b242c",
                                    fontSize: "0.75em",
                                    fontWeight: "700",
                                  }
                                  : {
                                    color: "#506690",
                                    fontSize: "0.75em",
                                    fontWeight: "700",
                                  }
                              }
                            >
                              {record.estadoEntrega}
                            </span>
                            {record.estadoEntrega === "Entregada" && (
                              <>
                                <span class="d-block smaller text-muted">
                                  {"ENTREGA: "}
                                  <span className="fw-bold">
                                    {record.responsableEntrega?.nombre}
                                  </span>
                                </span>
                                <span class="d-block smaller text-muted">
                                  {"FECHA DE ENTREGA: "}
                                  <span className="fw-bold">
                                    {formatDate(record.fechaSalida)}
                                  </span>
                                </span>
                              </>
                            )}
                          </td>
                          <td class="dropstart">
                            {/* <!-- options --> */}
                            {record.tiposervicio === "Gasto" ? (
                              ""
                            ) : (
                              <a
                                class="btn btn-sm btn-light btn-icon btn-ghost text-muted rounded-circle dropdown-toggle"
                                href="javascript:void(0)"
                                role="button"
                                data-bs-toggle="dropdown"
                                data-bs-offset="0,0"
                                aria-expanded={index === areaExpanded}
                                onClick={(event) => {
                                  event.preventDefault();
                                  handleRowMenu(index);
                                }}
                              >
                                <span class="group-icon">
                                  <svg
                                    height="18px"
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                                    ></path>
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18px"
                                    height="18px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                  </svg>
                                </span>
                              </a>
                            )}
                            <ul
                              className={
                                index === areaExpanded
                                  ? "dropdown-menu dropdown-menu-clean show"
                                  : "dropdown-menu dropdown-menu-clean"
                              }
                              style={{
                                position: "absolute",
                                inset: "0px 0px auto auto",
                                margin: "0px",
                                transform: "translate(-56px, 11px)",
                              }}
                            >
                              <li class="small px-3 py-2 text-muted">
                                Opciones
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="javascript:void(0)"
                                  onClick={() => resendEmailTicket(record.uuid)}
                                >
                                  <ForwardToInboxIcon fontsize="small" />
                                  <span>Reenviar orden</span>
                                </a>
                              </li>
                              {printIsAuto ? (
                                <li>
                                  <a
                                    class="dropdown-item"
                                    href="javascript:void(0)"
                                    onClick={() => enviarImpresion(record.uuid)}
                                  >
                                    <LocalPrintshopOutlinedIcon fontSize="small" />
                                    <span class="w-100">Imprime</span>
                                  </a>
                                </li>
                              ) : null}

                              {record.estadoEntrega !== "Entregada" && (
                                <li>
                                  <a
                                    class="dropdown-item"
                                    href="javascript:void(0)"
                                    onClick={() =>
                                      handleOpenModalEntrega(
                                        record.uuid,
                                        record.estadoEntrega
                                      )
                                    }
                                  >
                                    <AssignmentTurnedInIcon fontSize="small" />
                                    <span>Registrar entrega</span>
                                  </a>
                                </li>
                              )}
                            </ul>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
            {/* <!-- Modal : entrega --> */}

            <div
              className={openModalEntrega ? "modal fade show" : "modal fade"}
              id="pago-add"
              tabindex="-2"
              aria-labelledby="pago-add-label"
              aria-hidden={!openModalEntrega}
              aria-modal={openModalEntrega}
              role="dialog"
              style={
                openModalEntrega
                  ? {
                    display: "block",
                    backgroundColor: "#a2313738",
                  }
                  : {
                    zIndex: "-2",
                    display: "block",
                  }
              }
            >
              <div
                method="post"
                action="#"
                className={
                  openModalEntrega
                    ? "fade show modal-dialog form-validate modal-dialog modal-md modal-dialog-centered text-start"
                    : "fade modal-dialog form-validate modal-dialog modal-md modal-dialog-centered text-start"
                }
              >
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="pago-add-label">
                      Registrar entrega
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleOpenModalEntrega}
                    ></button>
                  </div>
                  <RegistrarEntrega
                    onSumit={registrarEntrega}
                    closeModal={handleOpenModalEntrega}
                  />
                </div>
              </div>
            </div>

            {/* <!-- pagination, selected items --> */}
            <div class="row">
              <div class="col py-3 text-center text-md-start">
                <ul class="list-unstyled m-0 p-0">
                  <li class="list-item">
                    {/* Ticket*/}
                    <a
                      class="text-decoration-none"
                      href="javascript:void(0)"
                      role="button"
                    >
                      <PictureAsPdfIcon fontSize="small" />
                      {!mostrarPDF && (
                        <span class="d-sm-inline-block ps-2">
                          <button onClick={handleGenerarPDF} style={{
                            color: "var(--color-dark-variant)", outline: "none",
                            border: "none",
                            backgroundColor: "transparent"
                          }}>
                            GENERAR PDF
                          </button>
                        </span>

                      )}
                      {mostrarPDF && (
                        <span class="d-sm-inline-block ps-2">
                          <PDFDownloadLink
                            document={
                              <Document
                                search={filterSearch}
                                filtero={filtero}
                                ordenTotal={order}
                                ordenFechaEntrega={orderfe}
                                ordenFechaRecepcion={orderfr}
                              />
                            }
                            fileName={`LET_TICKETS.pdf`}
                            style={{ padding: "0.5rem" }}
                          >
                            {({ blob, url, loading, error }) =>
                              loading ? "Cargando..." : "Descargar todo"
                            }
                          </PDFDownloadLink>
                        </span>
                      )}
                    </a>
                  </li>
                </ul>{" "}
              </div>

              <div class="col-md-auto py-3">
                {/* <!-- pagination --> */}
                <TablePagination
                  style={{ color: "var(--color-dark-variant)" }}
                  component="div"
                  classes="recent-orders"
                  labelRowsPerPage="Items por pagina"
                  count={total}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* /main */}
    </>
  );
};

export default TablaTickets;

/* TICKET: 
<a
                      class="text-decoration-none"
                      href="javascript:void(0)"
                      role="button"
                    >
                      <PictureAsPdfIcon fontSize="small" />
                      <span class="d-sm-inline-block ps-2">
                        <PDFDownloadLink
                          document={
                            <Document
                              search={filterSearch}
                              filtero={filtero}
                              ordenTotal={order}
                              ordenFechaEntrega={orderfe}
                              ordenFechaRecepcion={orderfr}
                            />
                          }
                          fileName={`LET_TICKETS.pdf`}
                          style={{ padding: "0.5rem" }}
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? "Cargando..." : "Descargar todo"
                          }
                        </PDFDownloadLink>
                      </span>
                    </a>
                    */