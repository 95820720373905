import { View, Text, StyleSheet } from "@react-pdf/renderer";

// Estilos personalizados para la tabla
const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableRowTotales: {
    margin: "auto",
    flexDirection: "row",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    textAlign: "left",
    width: "100%",
    columnGap: 3,
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColA: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColB: {
    width: "30%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 3,
    fontSize: 9,
  },
  tableCellB: {
    margin: "auto",
    marginTop: 1,
    fontSize: 9,
  },
  tableCellTotales: {
    margin: "auto",
    marginTop: 2,
    fontSize: 9,
    marginLeft: 3,
  },
  headerCol: {
    backgroundColor: "#d3d3d3",
  },
  fontnegrita: { fontFamily: "Lato Bold" },
  totalSmall: {},
});

const formatDateCrate = (fecha) => {
  if (!fecha) {
    return "0000-00-00";
  }
  const newdatec = new Date(fecha);
  return new Intl.DateTimeFormat("az", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "numeric",
  }).format(newdatec);
};

const totalTicket = (totalt) => {
  if (totalt > 0) {
    return <Text style={styles.tableCell}>${totalt}</Text>;
  }
  return <Text style={styles.tableCell}>${totalt}</Text>;
};

const metodosPago = (listaMetodos, total) => {
  let sumaPagos = 0;
  let metodo = "";

  listaMetodos.map((metodoPago) => {
    sumaPagos += Number(metodoPago.precio);
    metodo = metodoPago.metodopago;
  });

  return (
    <>
      <View style={[styles.tableCol]}>
        <Text style={styles.tableCell}>
          <Text style={[styles.totalSmall, styles.fontnegrita]}>
            {" "}
            $ {sumaPagos}
          </Text>{" "}
          / <Text style={styles.totalSmall}>{total}</Text>
        </Text>
      </View>
      <View style={[styles.tableCol]}>
        <Text style={styles.tableCell}>{metodo}</Text>
      </View>
    </>
  );
};

// Componente que renderiza la tabla
const MyTable = ({ rows, ingresos, totales, total }) => {
  return (
    <View style={styles.table} wrap={true}>
      {/* Fila del header */}
      <View style={styles.tableRow} wrap={false}>
        <View style={[styles.tableColA, styles.headerCol]}>
          <Text style={styles.tableCell}>ORDEN</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>FECHA</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>PAGO / TOTAL</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>MÉTODO</Text>
        </View>
        <View style={[styles.tableColB, styles.headerCol]}>
          <Text style={styles.tableCell}>CLIENTE</Text>
        </View>
      </View>
      {rows?.map((row, index) => (
        <>
          <View key={index} style={styles.tableRow} wrap={false}>
            <View style={styles.tableColA}>
              <Text style={styles.tableCell}>{row.uuid}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {formatDateCrate(row.fechaRecepcion)}
              </Text>
            </View>
            {metodosPago(row.pagos, row.total)}
            <View style={styles.tableColB}>
              <Text style={styles.tableCellB}>{row.cliente?.nombre}</Text>
            </View>
          </View>
        </>
      ))}
      {/* Fila del header */}
      <View key="totalesindex" style={styles.tableRowTotales} wrap={false}>
        <View style={styles.tableCellTotales}>
          <Text>TOTALES: </Text>
        </View>
        <View>
          <Text style={styles.tableCellTotales}>
            EFECTIVO: ${ingresos.efectivo}
          </Text>
        </View>
        <View>
          <Text style={styles.tableCellTotales}>
            TRANS: ${ingresos.transferencia}
          </Text>
        </View>
        <View>
          <Text style={styles.tableCellTotales}>
            {" "}
            TARJETA: ${ingresos.tarjeta}
          </Text>
        </View>
        <View>
          <Text style={styles.tableCellTotales}>
            {" "}
            DYC: ${ingresos.DyC}
          </Text>
        </View>
        <View>
          <Text style={styles.tableCellTotales}>
            {" "}
            PG: ${ingresos.PG}
          </Text>
        </View>
        <View>
          <Text style={styles.tableCellTotales}>
            ORDENES: ${totales.ordenes}
          </Text>
        </View>
        <View>
          <Text style={[styles.tableCellTotales]}>PAGOS: ${total}</Text>
        </View>
      </View>
    </View>
  );
};

export default MyTable;
