/* React */
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EventIcon from "@mui/icons-material/Event";

/* Componentes y dependencias */
import Preloader from "../../utils/PreloaderElement";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthContext";
import UsuarioRequeriments from "./UsuarioRequeriments";
/* Servicios */
import TicketsService from "../../../services/Tickets.service";
import CrearPago from "./CrearPago";
import RegistrarEntrega from "./RegistrarEntrega";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Document from "./Document/Document";
import DocumentPago from "./DocumentPago/Document";
import PrintIcon from "@mui/icons-material/Print";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import {
  Alert,
  Grid,
  IconButton,
  NativeSelect,
  TextareaAutosize,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Modal from "./Modal";
import BuscarUsuario from "../inventario/BuscarUsuario";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import BuscarPoducto from "./BuscarPoducto";
import ProductNota from "./ProductNota";
import CortesService from "../../../services/Corte.service";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { usePrint } from "../../../hooks/usePrint";

const TicketDetails = () => {
  const authorized = ["General", "Administrativo", "Administrador"];
  const newdate = new Date();
  const { id } = useParams();
  const [currentPago, setCurrentPago] = useState(null);
  let today = new Date();

  const { currentUser } = useContext(AuthContext);
  const [usuario, setUsuario] = useState(null);
  const [productos, setProductos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [pagado, setPagado] = useState(0);
  const navigate = useNavigate();
  const [servicios, setServicios] = useState([]);
  const [nota, setNota] = useState("");
  const [ticket, setTicket] = useState(null);
  const [latest, setLatest] = useState([]);
  const [openModalPago, setOpenModalPago] = useState(false);
  const [openModalEntrega, setOpenModalEntrega] = useState(false);
  const [metodoPago, setMetodoPago] = useState("Efectivo");
  const [pagos, setPagos] = useState([]);
  const [update, setUpdate] = useState(false);
  const [responsableServicio, setResponsableServicio] = useState(null);
  const [openModalClienteRequerimientos, setOpenModalClienteRequerimientos] =
    useState(false);

  const [responsableMovimiento, setResponsableMovimiento] = useState(null);
  const [movimientos, setMovimientos] = useState([]);
  const [movimientosRegister, setMovimientosRegister] = useState([]);
  const [tiposervicio, setTiposervicio] = useState("Normal");
  const [fechaEntrega, setFechaEntrega] = useState("");

  const [openModalResponsable, setOpenModalResponsable] = useState(false);
  const [result, setResult] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const authorizedTipo = authorized.includes(currentUser.tipo);

  const [openModalProductoNota, setOpenModalProductoNota] = useState(false);
  const [productSelected, setProductSelected] = useState(null);
  const [jornada, setJornada] = useState(null);

  const [openModalImprime, setOpenModalImprime] = useState(false);

  const [waitingPrint, setWaitingPrint] = useState(false);
  const { printUrl, printIsAuto } = usePrint();

  const handleOpenModalImprime = () => {
    setOpenModalImprime((old) => !old);
  };

  const impresionManual = async (url) => {
    printUrl(url);
  };

  const handleOpenModalProductoNota = (newClientOption) => {
    if (openModalProductoNota) {
      setProductSelected(null);
    }
    setOpenModalProductoNota(!openModalProductoNota);
    // setClienteOption(newClientOption);
  };

  const handleOpenModalClienteRequrimientos = (newClientOption) => {
    setOpenModalClienteRequerimientos(!openModalClienteRequerimientos);
    // setClienteOption(newClientOption);
  };

  const handleResponsableServicio = (newCliente) => {
    let changecliente = { ...newCliente.value, nombre: newCliente.value.label };
    setResponsableMovimiento(changecliente);
  };

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const handleOpenModalResponsable = (newClientOption) => {
    if (movimientosRegister.length < 1) {
      toast.info("Sin movimientos");
      return;
    }
    setOpenModalResponsable(!openModalResponsable);
  };

  const onUpdate = () => {
    setUpdate((oldUpdate) => !oldUpdate);
  };
  const handleOpenModalPago = () => {
    setOpenModalPago(!openModalPago);
  };
  const handleOpenModalEntrega = () => {
    setOpenModalEntrega(!openModalEntrega);
  };

  const handleMetodoPago = (newMetodoPago) => {
    console.log(newMetodoPago);
    setMetodoPago(newMetodoPago.target.value);
  };

  const handleCliente = async (newCliente) => {
    console.log(newCliente);
    let changecliente = { ...newCliente.value, nombre: newCliente.value.label };
    setUsuario(changecliente);
  };

  const formatDateLocal = (date, tipo) => {
    if (!date) {
      return "00/00/0000";
    }
    const newdate = new Date(date);

    if (tipo !== "Normal") {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        hourCycle: "h12",
      };
      return newdate
        .toLocaleString("es-ES", options)
        .replace(/(\d+)\/(\d+)\/(\d+), (\d+:\d+) ([APM]+)/, "$1-$2-$3 $4 $5");
    }

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    return newdate
      .toLocaleString("es-ES", options)
      .replace(/(\d+)\/(\d+)\/(\d+),/, "$1-$2-$3");
  };

  const formatDate = (date) => {
    const newdate = new Date(date);
    return newdate.toLocaleString();
  };

  const formatDateNoTime = (date) => {
    const newdate = new Date(date);
    return newdate.toLocaleDateString();
  };

  const getTicket = async () => {
    try {
      const data = await TicketsService.getById(id);

      setProductos([]);
      setUsuario(data.cliente);
      setTicket(data);
      setNota(data.nota);
      setPagado(Number(data.pagado));
      setPagos((old) => data.pagos);
      setResponsableServicio(data.responsableservicio);
      setMovimientos(data.movimientosTiket);
      setTiposervicio(data.tiposervicio);

      if (data.fecha) {
        setFechaEntrega(
          new Intl.DateTimeFormat("az", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }).format(new Date(data.fecha))
        );
      }

      let detallesTiket = [];

      data.detallesTiket.map(async (detalle) => {
        let newProduct = {
          cantidad: detalle.cantidad,
          nota: detalle.nota,
          value: {
            label: detalle.producto.nombre,
            id: detalle.producto.id,
            categoria: detalle.producto.categoria?.tipo,
            precio:
              (detalle.producto.precios[0]?.precio / 100) *
              (100 - detalle.producto.descuento),
            precioOriginal: detalle.producto.precios[0]?.precio,
            descuento: detalle.producto.descuento,
          },
        };
        detallesTiket.push(newProduct);
      });

      setProductos(detallesTiket);

      const dataLatest = await TicketsService.getLatest(data.cliente.uuid);
      setLatest(dataLatest);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleEditMode = (status) => {
    setEditMode(status && authorizedTipo);
    if (!status) {
      onUpdate();
      setMovimientosRegister([]);
    }
  };

  const getLatestTickets = async () => {
    console.log(id);
    setLoading(true);
    try {
      const data = await TicketsService.getLatest(usuario.uuid);
      console.log(data);
      setLatest(data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const enviarImpresion = async (uuid) => {
    if (waitingPrint) {
      return;
    }
    setWaitingPrint(true);
    try {
      let newTicket = { id: uuid };
      const res = await TicketsService.resendPrint(newTicket);
      printUrl(res.url);
    } catch (error) {
      toast.error("Error. No enviado!");
    } finally {
      setWaitingPrint(false);
    }
  };

  const addPago = async (cantidad) => {
    if (!jornada) {
      toast.info("Jornada no iniciada.");
      return;
    }

    if (cantidad.cantidad <= 0) {
      return;
    }
    setLoading(true);
    try {
      let data = {
        uuid: id,
        cantidad: cantidad.cantidad,
        metodopago: metodoPago,
        usuario: currentUser.id,
        fecha: newdate.toISOString(),
      };

      const dataResponse = await TicketsService.pago(data);

      toast.success("Pago guardado");

      if (dataResponse && dataResponse.pago) {
        setCurrentPago(dataResponse.pago);
      }

      onUpdate();

      if (printIsAuto) {
        printUrl(dataResponse.url);
      } else {
        setOpenModalImprime(true);
      }
    } catch (error) {
      toast.error("No se guardado el pago");
      setLoading(false);
    }
  };

  const resendEmailTicket = async () => {
    try {
      let newdata = {
        id: id,
      };
      await TicketsService.resendEmail(newdata);
      toast.success("Reenviado");
    } catch (error) {
      toast.error("Error,No se ha reenviado");
    }
  };

  const registrarEntrega = async (cantidad) => {
    setLoading(true);
    try {
      let data = {
        uuid: id,
        estadoEntrega: cantidad.selectedValue,
        notaEntrega: cantidad.nota,
        responsableEntrega: cantidad.responsableEntrega,
        contrasena: cantidad.contrasena,
        fechaSalida: newdate.toISOString(),
      };

      if (!data.responsableEntrega) {
        toast.error("No has seleccionado un responsable");
        setLoading(false);
        return;
      }
      await TicketsService.update(data);
      toast.success("Entrega registrada");
      onUpdate();
    } catch (error) {
      toast.error(error.error);
      setLoading(false);
    }
  };

  const addProduct = async (newProduct) => {
    let exist = false;
    let filter = productos.map((op) => {
      if (op.value.id === newProduct.value.id) {
        exist = true;
        let aproduct = {
          ...newProduct,
          cantidad: parseInt(newProduct.cantidad) + parseInt(op.cantidad),
        };
        agregarCambioValue(
          "Producto",
          newProduct.value.label,
          aproduct.cantidad,
          op.cantidad
        );
        return aproduct;
      }

      return op;
    });
    if (exist) {
      setProductos(filter);
    } else {
      setProductos([...productos, newProduct]);
      agregarCambioValue(
        "Producto",
        newProduct.value.label,
        newProduct.cantidad,
        0
      );
    }
  };

  const addProductNote = async (newProduct) => {
    let exist = false;
    let filter = productos.map((op) => {
      if (op.value.id === newProduct.value.id) {
        exist = true;
        let aproduct = {
          ...newProduct,
        };
        agregarCambioValue(
          "Producto",
          newProduct.value.label + "-nota",
          aproduct.nota,
          op.nota
        );
        return aproduct;
      }

      return op;
    });
    if (exist) {
      setProductos(filter);
    } else {
      setProductos([...productos, newProduct]);
      agregarCambioValue(
        "Producto",
        newProduct.value.label + "-nota",
        newProduct.nota,
        ""
      );
    }
  };


  const getTotal = () => {
    let newTotal = 0;
    productos.map((p) => {
      newTotal += p.value.precio * p.cantidad;
    });
    setTotal(newTotal);
  };

  const removeProduct = (id) => {
    let remove = productos.filter((p) => p.value.id === id);
    let oldProductos = productos.filter((p) => p.value.id !== id);
    agregarCambioValue(
      "Producto",
      remove[0].value.label,
      0,
      remove[0].cantidad
    );
    setProductos(oldProductos);
    getTotal();
  };

  const handleKeyDown = (event) => {
    if (event.key !== "Enter") {
      return;
    }

    saveTicket();
  };

  function validarString(str) {
    // Eliminar espacios en blanco
    str = str.trim();

    // Verificar si el string es vacío
    if (str.length === 0) {
      return false;
    }

    // Verificar si el string contiene solo caracteres especiales
    const regex = /^[\s\n\t]+$/;
    if (regex.test(str)) {
      return false;
    }

    return true;
  }

  const saveTicket = async () => {
    try {
      if (!jornada) {
        toast.info("Jornada no iniciada.");
        return;
      }

      if (!fechaEntrega) {
        toast.info("Selecciona una fecha de entrega.");
        return;
      }
      if (!responsableMovimiento?.id) {
        toast.info("Selecciona un responsable de servicio.");
        return;
      }
      if (productos.length < 1) {
        toast.info("Sin productos");
        return;
      }
      if (!result) {
        toast.info("Contraseña vacía");
        return;
      }
      setLoading(true);

      let entrega = new Date(fechaEntrega);

      const newTicket = {
        uuid: ticket.uuid,
        fecha: entrega.getTime(),
        pagado: pagado,
        total: total,
        detalles: productos,
        tiposervicio: tiposervicio,
        nota: validarString(nota) ? nota : "",
        responsableServicio: responsableMovimiento.id,
        movimientosRegister: movimientosRegister,
      };

      if (result) {
        newTicket.contrasena = result;
        await TicketsService.updateTicket(newTicket);
        toast.success("Guardado");
        setOpenModalResponsable(false)
        //navigate("/tickets");
      }
    } catch (error) {
      toast.error("Error. No se ha guardado!");
    } finally {
      setLoading(false);
    }
  };

  const toMoney = (value) => {
    const money = Number(value);

    if (isNaN(money)) {
      return value;
    }

    return money.toLocaleString("en", {
      style: "currency",
      currency: "MXN",
    });
  };

  const handleTipoServicio = async (newMetodoPago) => {
    setTiposervicio(newMetodoPago.target.value);
    agregarCambioValue(
      "ORDEN",
      "tiposervicio",
      newMetodoPago.target.value,
      ticket.tiposervicio
    );
  };

  const agregarCambio = async (propChange, newValue) => {
    let movimientosOld = movimientos.filter(
      (move) => move.propiedad !== propChange
    );

    if (newValue !== ticket[propChange]) {
      movimientosOld.push({
        propiedad: propChange,
        newValue: newValue,
        oldValue: ticket[propChange],
        // articulo: tipoarticulo,
      });
    }
    setMovimientos(movimientosOld);
  };

  const agregarCambioValue = async (
    articulo,
    propChange,
    newValue,
    oldValue
  ) => {
    let newOldValue = oldValue;
    let movimientosOld = movimientosRegister.filter((move) => {
      if (move.propiedad !== propChange) {
        return true;
      }
      newOldValue = move.oldValue;
      return false;
    });

    if (newValue !== newOldValue) {
      movimientosOld.push({
        propiedad: propChange,
        newValue: newValue,
        oldValue: newOldValue,
        articulo: articulo,
      });
    }
    setMovimientosRegister(movimientosOld);
  };

  const getServicios = async () => {
    try {
      setServicios([
        { titulo: "Normal", precios: [{ props: { tiempo: 3, precio: 0 } }] },
        { titulo: "Express", precios: [{ props: { tiempo: 1, precio: 0 } }] },
        {
          titulo: "Hora especifica",
          precios: [{ props: { tiempo: 0, precio: 0 } }],
        },
      ]);
    } catch (error) {
      toast.error(error);
    }
  };

  const getJornada = async () => {
    try {
      const data = await CortesService.revisarJornada();
      if (data && data.data[0]) {
        setJornada(data.data);
      } else {
        setJornada(null);
      }
    } catch (error) {
      console.log(error);
      toast.error("Datos de jornada no recuperados");
    }
  };

  useEffect(() => {
    getTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productos]);

  useEffect(() => {
    cagarDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, update]);

  const cagarDatos = async () => {
    setLoading(true);
    Promise.all([getJornada(), getTicket(), getServicios()]).finally(() => {
      setLoading(false);
    });
  };
  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* <!-- PAGE TITLE --> */}
            {!jornada && <Alert severity="warning">jornada no iniciada</Alert>}
            <header style={jornada ? null : { marginTop: "0.5rem" }}>
              <div class="row g-1 align-items-center">
                <div class="col">
                  <h1 class="h4">Orden de servicio</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb small">
                      <li class="breadcrumb-item">
                        <a href="javascript:void(0);">{ticket?.uuid}</a>
                      </li>
                    </ol>
                  </nav>
                </div>

                <div class="col-auto">
                  <a
                    href="javascript:void(0);"
                    role="button"
                    class="btn btn-sm btn-primary d-inline-flex align-items-center"
                    onClick={() => navigate(-1)}
                  >
                    <span class="d-none d-sm-inline-block ps-2">Regresar</span>
                  </a>
                </div>
              </div>
            </header>
            {/* <!-- Order items --> */}
            <div class="row g-2">
              <div class="col">
                {/* <!-- Order items --> */}
                <div class="card border-0 h-100">
                  <div class="card-header p-4 border-bottom-0 fw-bold d-flex justify-content-between">
                    Articulos ({productos?.length})
                    <div>
                      {authorizedTipo &&
                      ticket?.estadoEntrega === "En existencia" ? (
                        !editMode ? (
                          <IconButton onClick={() => handleEditMode(true)}>
                            <ModeEditIcon />
                          </IconButton>
                        ) : (
                          <>
                            <IconButton
                              onClick={() => handleOpenModalResponsable()}
                            >
                              <SaveIcon />
                            </IconButton>
                            <IconButton onClick={() => handleEditMode(false)}>
                              <CloseIcon />
                            </IconButton>
                          </>
                        )
                      ) : null}
                    </div>
                  </div>
                  <div class="card-body p-4 h-100">
                    <div class="table-responsive-md mb-3">
                      <table class="table table-align-middle mb-0" role="grid">
                        <thead>
                          <tr>
                            <th
                              class="small text-muted"
                              style={{ width: "104px" }}
                            >
                              {/* <!-- image --> */}
                            </th>
                            <th class="small text-muted">PRODUCTO</th>
                            <th
                              class="small text-muted text-center"
                              style={{ width: "150px" }}
                            >
                              CANTIDAD
                            </th>
                            <th class="small text-muted text-center">PRECIO</th>
                            <th class="small text-muted text-center">
                              SUBTOTAL
                            </th>
                            <th
                              class="small text-muted"
                              style={{ width: "64px" }}
                            >
                              {/* <!-- options --> */}
                            </th>
                          </tr>
                        </thead>
                        <tbody id="checkall-list">
                          {!productos[0] && (
                            <tr>
                              <td></td>
                              <td>
                                {/* <!-- product --> */}
                                <a
                                  href="javascript:void(0)"
                                  class="link-normal fw-medium"
                                >
                                  -
                                </a>
                                <span class="d-block text-muted small"></span>
                              </td>
                              <td class="text-center">-</td>
                              <td class="text-center">
                                {/* <!-- unit price --> */}
                                {/* <del class="d-block text-muted">$50.00</del> */}
                                <span class="d-block text-success">-</span>
                              </td>
                              <td class="text-center fw-bold">
                                {/* <!-- subtotal --> */}-
                              </td>
                              <td class="dropstart text-end">
                                {/* <!-- options --> */}
                              </td>
                            </tr>
                          )}
                          {productos?.map((product, index) => (
                            <>
                              <tr
                                key={index}
                                style={
                                  product.nota
                                    ? { borderBottomColor: "white" }
                                    : null
                                }
                              >
                                <td>
                                  {/* <!-- image --> */}
                                  {/* <img
                                class="border border-light p-1"
                                width="80"
                                src="../html_frontend/demo.files/images/various/product/speakers/thumb/1-min.jpg"
                                alt="..."
                              /> */}
                                </td>
                                <td>
                                  {/* <!-- product --> */}
                                  <a
                                    href="javascript:void(0)"
                                    class="link-normal fw-medium"
                                  >
                                    {product.value.label}
                                  </a>
                                  {/* <span class="d-block text-muted small">
                                  {product.value.categoria}
                                </span> */}
                                </td>
                                <td class="text-center">
                                  {editMode ? (
                                    <div className=" d-flex align-items-center justify-content-center">
                                      <IconButton
                                        style={{
                                          width: "2rem",
                                          height: "2rem",
                                        }}
                                        onClick={() =>
                                          addProduct({
                                            ...product,
                                            cantidad: -1,
                                          })
                                        }
                                      >
                                        -
                                      </IconButton>
                                      <span>{product.cantidad}</span>{" "}
                                      <IconButton
                                        style={{
                                          width: "2rem",
                                          height: "2rem",
                                        }}
                                        onClick={() =>
                                          addProduct({
                                            ...product,
                                            cantidad: 1,
                                          })
                                        }
                                      >
                                        +
                                      </IconButton>
                                    </div>
                                  ) : (
                                    product.cantidad
                                  )}
                                </td>
                                <td class="text-center">
                                  {product.value?.precioOriginal + "" !==
                                    product.value?.precio + "" && (
                                    <del class="d-block text-muted">
                                      ${product.value.precioOriginal}
                                    </del>
                                  )}
                                  <span class="d-block text-success">
                                    ${product.value.precio}
                                  </span>
                                </td>
                                <td class="text-center fw-bold">
                                  {/* <!-- subtotal --> */}
                                  {toMoney(
                                    Number(product.value.precio) *
                                      Number(product.cantidad)
                                  )}
                                </td>
                                <td class="dropstart text-end">
                                  {editMode ? (
                                    <a
                                      class="dropdown-item"
                                      href="javascript:void(0);"
                                      onClick={() =>
                                        removeProduct(product.value.id)
                                      }
                                    >
                                      <svg
                                        class="text-danger"
                                        width="18px"
                                        height="18px"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <polyline points="3 6 5 6 21 6"></polyline>
                                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                        <line
                                          x1="10"
                                          y1="11"
                                          x2="10"
                                          y2="17"
                                        ></line>
                                        <line
                                          x1="14"
                                          y1="11"
                                          x2="14"
                                          y2="17"
                                        ></line>
                                      </svg>
                                    </a>
                                  ) : null}
                                </td>
                              </tr>
                              {product.nota && !editMode && (
                                <tr
                                  key={index + "nota"}
                                  style={{ borderTopColor: "white" }}
                                >
                                  <th></th>
                                  <td colSpan={4}>
                                    <span class="d-block text-muted smaller">
                                      {product.nota}
                                    </span>
                                  </td>
                                  <td></td>
                                </tr>
                              )}
                              {editMode && (
                                <tr
                                  key={index + "nota"}
                                  style={{ borderTopColor: "white" }}
                                >
                                  <th></th>
                                  <td colSpan={4}>
                                    <span
                                      class="d-block smaller btn-link"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setProductSelected(product);
                                        handleOpenModalProductoNota();
                                      }}
                                    >
                                      <svg
                                        width="16px"
                                        height="16px"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                      </svg>{" "}
                                      {product.nota}
                                    </span>
                                  </td>
                                  <td></td>
                                </tr>
                              )}
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <Modal
                      status={openModalProductoNota}
                      handleStatus={handleOpenModalProductoNota}
                      headerText="Nota"
                    >
                      <ProductNota
                        product={productSelected}
                        onSumit={addProductNote}
                        closeModal={handleOpenModalProductoNota}
                      />
                    </Modal>

                    <div class="row g-4">
                      <div class="order-2 order-md-1 col small">
                        <div className="d-flex">
                          <strong className="w-100">Notas:</strong>
                        </div>
                        {editMode ? (
                          <TextareaAutosize
                            className="text-uppercase"
                            value={nota}
                            style={{
                              width: "100%",
                              height: "76px",
                              border: "solid 1px lightgrey",
                              borderRadius: "8px",
                              marginTop: "0.5rem",
                            }}
                            minRows={2}
                            maxRows={2}
                            onChange={(event) => {
                              setNota(event.target.value);
                              agregarCambioValue(
                                "ORDEN",
                                "NOTA",
                                event.target.value,
                                nota
                              );
                            }}
                          />
                        ) : (
                          <div style={{ textAlign: "justify" }}>{nota}</div>
                        )}
                      </div>
                      <div class="order-1 order-md-2 col-md-3 text-end">
                        {editMode ? (
                          <button
                            type="button"
                            class="btn btn-sm btn-light text-uppercase"
                            data-bs-toggle="modal"
                            data-bs-target="#item-add"
                            onClick={handleOpenModal}
                          >
                            <svg
                              width="18px"
                              height="18px"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <line x1="12" y1="5" x2="12" y2="19"></line>
                              <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg>
                            <span>producto</span>
                          </button>
                        ) : null}
                        {/* <!-- Modal : item add --> */}

                        <div
                          className={
                            openModal ? "modal fade show" : "modal fade"
                          }
                          id="item-add"
                          tabindex="-1"
                          aria-labelledby="item-add-label"
                          aria-hidden={!openModal}
                          aria-modal={openModal}
                          role="dialog"
                          style={
                            openModal
                              ? {
                                  display: "block",
                                  backgroundColor: "#a2313738",
                                }
                              : {
                                  zIndex: "-1",
                                  display: "block",
                                }
                          }
                        >
                          <div
                            method="post"
                            action="#"
                            className={
                              openModal
                                ? "fade show modal-dialog form-validate modal-dialog modal-md modal-dialog-centered text-start"
                                : "fade modal-dialog form-validate modal-dialog modal-md modal-dialog-centered text-start"
                            }
                          >
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" id="item-add-label">
                                  Agregar producto
                                </h5>
                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  onClick={handleOpenModal}
                                ></button>
                              </div>
                              <BuscarPoducto
                                onSumit={addProduct}
                                closeModal={handleOpenModal}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row g-4 justify-content-end my-5">
                      <div class="col">
                        <ul class="list-unstyled m-0 p-0 fs-6">
                          <li class="list-item">
                            Tipo de servicio:{" "}
                            {editMode ? (
                              <NativeSelect
                                defaultValue={tiposervicio}
                                onChange={handleTipoServicio}
                                className="px-2 text-uppercase"
                              >
                                {servicios?.map((servicio, index) => (
                                  <option
                                    key={index}
                                    value={servicio.titulo}
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    {servicio.titulo.toUpperCase()}
                                  </option>
                                ))}
                              </NativeSelect>
                            ) : (
                              <span class="text-muted">
                                {ticket?.tiposervicio}
                              </span>
                            )}
                          </li>
                          <li class="list-item">
                            Estado de entrega:{" "}
                            <span
                              className={
                                ticket?.estadoEntrega === "Entregado"
                                  ? "badge bg-success"
                                  : "badge bg-secondary"
                              }
                            >
                              {ticket?.estadoEntrega}
                            </span>
                            {/* <span class="d-block text-muted small">
                              Order weight: 2Kg
                            </span>
                            <span class="d-block text-muted small">
                              Boxing: 1 &times; default box
                            </span> */}
                          </li>
                        </ul>
                      </div>
                      <div class="col-md-8 col-lg-7 text-end">
                        <dl class="row mb-0">
                          <dt class="col-10 fw-bold">
                            <dd class="col-6 fw-bold d-inline-grid gap-auto-2">
                              Total:
                            </dd>
                            <dd class="col-6 fw-bold d-inline-grid gap-auto-2">
                              {toMoney(total)}
                            </dd>
                          </dt>
                          <dd class="col-2 fw-bold"></dd>
                          <dt class="col-10 text-muted">
                            <dd class="col-6 text-muted d-inline-grid gap-auto-2">
                              Anticipo:
                            </dd>
                            <dd class="col-6 text-muted d-inline-grid gap-auto-2">
                              {toMoney(pagado)}
                            </dd>
                          </dt>
                          <dd class="col-2 text-muted">
                            <a
                              class="flex-none link-muted small d-inline-grid gap-auto-2"
                              href="javascript:void(0);"
                              onClick={handleOpenModalPago}
                            >
                              <AddCircleOutlineIcon />
                            </a>
                          </dd>
                          <dt class="col-10 fw-bold">
                            <dd class="col-6 fw-bold d-inline-grid gap-auto-2">
                              Saldo:
                            </dd>
                            <dd class="col-6 fw-bold d-inline-grid gap-auto-2 text-danger">
                              {toMoney(total - pagado)}
                            </dd>
                          </dt>
                          <dd class="col-2 fw-bold"></dd>
                          <dt class="col-10 fw-bold text-muted">
                            <dd class="col-6 fw-bold d-inline-grid gap-auto-2">
                              Fecha de entrega:
                            </dd>

                            {editMode ? (
                              <dd class="col-6 fw-bold d-inline-grid gap-auto-2">
                                <span style={{ width: "297px" }}>
                                  <input
                                    type="datetime-local"
                                    className="form-control shadow-none"
                                    placeholder="Recepcion"
                                    onChange={(e) => {
                                      agregarCambioValue(
                                        "ORDEN",
                                        "fechaEntrega",
                                        e.target.value,
                                        ticket?.fecha
                                      );
                                      setFechaEntrega(e.target.value);
                                    }}
                                    value={fechaEntrega}
                                  />
                                </span>
                              </dd>
                            ) : (
                              <dd
                                class="col-6 fw-bold d-inline-grid gap-auto-2"
                                style={{ paddingLeft: "0.5rem" }}
                              >
                                {formatDateLocal(
                                  ticket?.fecha,
                                  ticket?.tiposervicio
                                )}
                              </dd>
                            )}
                          </dt>
                        </dl>
                      </div>
                    </div>

                    {ticket?.notaEntrega && ticket?.notaEntrega !== "" && (
                      <div class="row g-4">
                        <div class="order-2 order-md-1 col small">
                          <div className="d-flex">
                            <strong className="w-100">Notas entrega:</strong>
                          </div>
                          <div>{ticket?.notaEntrega}</div>
                        </div>
                        <div class="order-1 order-md-2 col-md-3 text-end"></div>
                      </div>
                    )}
                  </div>
                  <div class="card-footer p-4">
                    <small class="d-block text-muted  d-flex">
                      Responsable del servicio:{" "}
                      <span class="d-block ps-4">
                        {responsableServicio?.nombre}
                      </span>
                    </small>

                    <div class="d-flex mt-4 justify-content-between">
                      <span
                        class="flex-none text-muted"
                        style={{ width: "120px" }}
                      >
                        {movimientosRegister.length > 0 &&
                          "Últimos movimientos"}
                      </span>
                      <span style={{ width: "297px" }}>
                        {movimientosRegister.map((move, index) => (
                          <div class="small" key={index}>
                            <div class="d-flex justify-content-between">
                              <span
                                class="flex-none text-muted"
                                style={{ width: "120px" }}
                              >
                                <span class="badge bg-secondary"></span>
                                {move.propiedad}
                              </span>
                              <span>{move.newValue}</span>
                            </div>
                            <a
                              class="link-muted small"
                              href="javascript:void(0)"
                            >
                              {move.fecha} {move.oldValue}
                            </a>
                          </div>
                        ))}
                      </span>
                    </div>

                    <div class="d-flex mt-4 justify-content-between">
                      <small class="d-block text-muted">
                        Movimientos orden ({movimientos.length})
                      </small>
                      <span className="col-8">
                        {movimientos.map((movi) => (
                          <>
                            {movi.operations?.map((move, index) => (
                              <div class="small" key={index}>
                                <div class="d-flex justify-content-between">
                                  <span
                                    class="flex-none text-muted"
                                    style={{ width: "120px" }}
                                  >
                                    <span class="badge bg-secondary"></span>
                                    ARTICULO
                                  </span>
                                  <span>
                                    {" "}
                                    {move.articulo
                                      ? move.articulo
                                      : "Sin articulo"}
                                  </span>
                                </div>
                                <div class="d-flex justify-content-between">
                                  <span
                                    class="flex-none text-muted"
                                    style={{ width: "125px" }}
                                  >
                                    <span class="badge bg-secondary"></span>
                                    {move.propiedad}
                                  </span>
                                  <span>{move.newValue}</span>
                                </div>
                                <a
                                  class="link-muted small"
                                  href="javascript:void(0)"
                                >
                                  {move.fecha} {move.oldValue}
                                </a>
                              </div>
                            ))}
                            <div
                              class="small"
                              style={{
                                marginBottom: "1rem",
                                borderBottom: "1px solid lightgray",
                              }}
                            >
                              <div class="d-flex justify-content-between">
                                <span
                                  class="flex-none"
                                  style={{ width: "120px" }}
                                >
                                  <span class="badge bg-secondary"></span>
                                  Autorizo
                                </span>
                                <a href="javascript:void(0)">
                                  {movi.responsable?.nombre}
                                </a>
                              </div>
                              <span>{formatDate(movi.createdAt)}</span>
                            </div>
                          </>
                        ))}
                      </span>
                    </div>
                  </div>
                </div>
                {/* <!-- /Order items --> */}
                {/* <!-- Modal : entrega --> */}

                <div
                  className={
                    openModalEntrega ? "modal fade show" : "modal fade"
                  }
                  id="pago-add"
                  tabindex="-2"
                  aria-labelledby="pago-add-label"
                  aria-hidden={!openModalEntrega}
                  aria-modal={openModalEntrega}
                  role="dialog"
                  style={
                    openModalEntrega
                      ? {
                          display: "block",
                          backgroundColor: "#a2313738",
                        }
                      : {
                          zIndex: "-2",
                          display: "block",
                        }
                  }
                >
                  <div
                    method="post"
                    action="#"
                    className={
                      openModalEntrega
                        ? "fade show modal-dialog form-validate modal-dialog modal-md modal-dialog-centered text-start"
                        : "fade modal-dialog form-validate modal-dialog modal-md modal-dialog-centered text-start"
                    }
                  >
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="pago-add-label">
                          Registrar entrega
                        </h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={handleOpenModalEntrega}
                        ></button>
                      </div>
                      <RegistrarEntrega
                        onSumit={registrarEntrega}
                        metodoPago={metodoPago}
                        total={total}
                        pagos={pagos}
                        handleMetodoPago={handleMetodoPago}
                        closeModal={handleOpenModalEntrega}
                      />
                    </div>
                  </div>
                </div>
                {/* <!-- Modal : pago add --> */}

                <div
                  className={openModalPago ? "modal fade show" : "modal fade"}
                  id="pago-add"
                  tabindex="-2"
                  aria-labelledby="pago-add-label"
                  aria-hidden={!openModalPago}
                  aria-modal={openModalPago}
                  role="dialog"
                  style={
                    openModalPago
                      ? {
                          display: "block",
                          backgroundColor: "#a2313738",
                        }
                      : {
                          zIndex: "-2",
                          display: "block",
                        }
                  }
                >
                  <div
                    method="post"
                    action="#"
                    className={
                      openModalPago
                        ? "fade show modal-dialog form-validate modal-dialog modal-md modal-dialog-centered text-start"
                        : "fade modal-dialog form-validate modal-dialog modal-md modal-dialog-centered text-start"
                    }
                  >
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="pago-add-label">
                          Agregar pago
                        </h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={handleOpenModalPago}
                        ></button>
                      </div>
                      <CrearPago
                        onSumit={addPago}
                        metodoPago={metodoPago}
                        total={total}
                        pagos={pagos}
                        handleMetodoPago={handleMetodoPago}
                        closeModal={handleOpenModalPago}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- side --> */}
              <div class="col-xl-4">
                <div class="card border-0 h-100">
                  <div class="card-header p-4 mb-3">
                    <div class="d-flex align-items-center">
                      <div class="w-100 ps-3">
                        <ul class="d-grid gap-2 list-unstyled m-0 p-0">
                          <li class="list-item">
                            {printIsAuto ? (
                              <a
                                class="text-decoration-none"
                                href="javascript:void(0)"
                                onClick={() => {
                                  enviarImpresion(id);
                                }}
                              >
                                <PrintIcon fontSize="small" />
                                <span style={{ marginInline: "0.5rem" }}>
                                  Imprime
                                </span>
                              </a>
                            ) : (
                              <a
                                class="text-decoration-none"
                                href="javascript:void(0)"
                              >
                                <PrintIcon fontSize="small" />
                                <PDFDownloadLink
                                  document={<Document id={id} />}
                                  fileName={`LET_ORDEN_${newdate.toLocaleDateString()}.pdf`}
                                  style={{ padding: "0.5rem" }}
                                >
                                  {({ blob, url, loading, error }) =>
                                    loading ? (
                                      "Cargando..."
                                    ) : (
                                      <a
                                        className="text-decoration-none"
                                        href="javascript:void(0);"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          impresionManual(url);
                                        }}
                                      >
                                        <span
                                          style={{ marginInline: "0.5rem" }}
                                        >
                                          Imprime
                                        </span>
                                      </a>
                                    )
                                  }
                                </PDFDownloadLink>
                              </a>
                            )}
                          </li>
                          <li class="list-item">
                            {/* <!-- customer name, orders --> */}
                            <a
                              class="text-decoration-none"
                              href="javascript:void(0)"
                            >
                              <PictureAsPdfOutlinedIcon fontSize="small" />
                              <PDFDownloadLink
                                document={<Document id={id} />}
                                fileName={`LET_ORDEN_${newdate.toLocaleDateString()}.pdf`}
                                style={{ padding: "0.5rem" }}
                              >
                                {({ blob, url, loading, error }) =>
                                  loading ? "Cargando..." : "Descargar PDF"
                                }
                              </PDFDownloadLink>
                            </a>
                            <a
                              class="link-muted small ms-2"
                              href="javascript:void(0)"
                            >
                              {/* 4 orders */}
                            </a>
                          </li>
                          <li class="list-item">
                            {/* <!-- customer name, orders --> */}
                            <a
                              class="text-decoration-none"
                              href="javascript:void(0)"
                              onClick={() => resendEmailTicket()}
                            >
                              <ForwardToInboxIcon fontsize="small" />
                              <span class="d-sm-inline-block ps-2">
                                Reenviar correo
                              </span>
                            </a>
                            <a
                              class="link-muted small ms-2"
                              href="javascript:void(0)"
                            >
                              {/* 4 orders */}
                            </a>
                          </li>
                          {ticket?.estadopago !== "Liquidada" ? (
                            <li class="list-item">
                              {/* <!-- customer name, orders --> */}
                              <a
                                class="text-decoration-none"
                                href="javascript:void(0)"
                                onClick={handleOpenModalPago}
                              >
                                <AttachMoneyIcon fontSize="small" />
                                <span class="d-sm-inline-block ps-2">
                                  Registrar pago
                                </span>
                              </a>
                              <a
                                class="link-muted small ms-2"
                                href="javascript:void(0)"
                              >
                                {/* 4 orders */}
                              </a>
                            </li>
                          ) : null}
                          {ticket?.estadoEntrega !== "Entregada" ? (
                            <li class="list-item">
                              <a
                                className="text-decoration-none text-muted"
                                href="javascript:void(0)"
                                role="button"
                                onClick={handleOpenModalEntrega}
                              >
                                <AssignmentTurnedInIcon fontSize="small" />
                                <span class="d-sm-inline-block ps-2">
                                  Registrar entrega
                                </span>
                              </a>

                              <a
                                class="link-muted small ms-2"
                                href="javascript:void(0)"
                              >
                                {/* 4 orders */}
                              </a>
                            </li>
                          ) : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card-header p-4 mb-3">
                    <div class="fw-bold d-flex align-items-center mb-3">
                      <span class="w-100">Cliente</span>
                    </div>
                    <div class="d-flex align-items-center">
                      {/* <!-- avatar --> */}
                      {/* <div
                        class="flex-none avatar avatar-lg bg-soft-primary rounded-circle"
                        style={{
                          backgroundImage:
                            "url(../html_frontend/demo.files/images/avatar/jessica_barden.jpg)",
                        }}
                      ></div> */}

                      {/* <!-- no avatar --> */}
                      {/* <!--  */}
                      {/* <div class="flex-none avatar avatar-lg bg-soft-primary rounded-circle">
                        <svg
                          class="opacity-25"
                          width="34px"
                          height="34px"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 460.8 460.8"
                          fill="currentColor"
                        >
                          <path d="M230.432,0c-65.829,0-119.641,53.812-119.641,119.641s53.812,119.641,119.641,119.641s119.641-53.812,119.641-119.641S296.261,0,230.432,0z"></path>
                          <path d="M435.755,334.89c-3.135-7.837-7.314-15.151-12.016-21.943c-24.033-35.527-61.126-59.037-102.922-64.784c-5.224-0.522-10.971,0.522-15.151,3.657c-21.943,16.196-48.065,24.555-75.233,24.555s-53.29-8.359-75.233-24.555c-4.18-3.135-9.927-4.702-15.151-3.657c-41.796,5.747-79.412,29.257-102.922,64.784c-4.702,6.792-8.882,14.629-12.016,21.943c-1.567,3.135-1.045,6.792,0.522,9.927c4.18,7.314,9.404,14.629,14.106,20.898c7.314,9.927,15.151,18.808,24.033,27.167c7.314,7.314,15.673,14.106,24.033,20.898c41.273,30.825,90.906,47.02,142.106,47.02s100.833-16.196,142.106-47.02c8.359-6.269,16.718-13.584,24.033-20.898c8.359-8.359,16.718-17.241,24.033-27.167c5.224-6.792,9.927-13.584,14.106-20.898C436.8,341.682,437.322,338.024,435.755,334.89z"></path>
                        </svg>
                      </div> */}
                      {/* --> */}

                      {/* <!-- customer details --> */}
                      <div class="w-100 ps-4">
                        <ul class="list-unstyled m-0 p-0">
                          <li class="list-item">
                            {/* <!-- customer phone --> */}
                            <a
                              class="link-normal small d-inline-grid gap-auto-2"
                              href="javascript:void(0);"
                            >
                              <span>Cliente:</span>
                              <span>{usuario?.uuid}</span>
                            </a>
                          </li>
                          <li class="list-item">
                            {/* <!-- customer phone --> */}
                            <a
                              class="link-normal small d-inline-grid gap-auto-2"
                              href="javascript:void(0);"
                            >
                              <span>nombre:</span>
                              <span className="fw-bold">{usuario?.nombre}</span>
                            </a>
                          </li>
                          <li class="list-item">
                            {/* <!-- customer email --> */}
                            <a
                              class="link-normal small d-inline-grid gap-auto-2"
                              href="javascript:void(0);"
                            >
                              <svg
                                class="text-muted"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                ></path>
                              </svg>
                              <span style={{ textTransform: "none" }}>
                                {usuario?.correo}
                              </span>
                            </a>
                          </li>
                          <li class="list-item">
                            {/* <!-- customer phone --> */}
                            <a
                              class="link-normal small d-inline-grid gap-auto-2"
                              href="javascript:void(0);"
                            >
                              <svg
                                class="flex-none text-muted me-2"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                <circle cx="12" cy="10" r="3"></circle>
                              </svg>
                              <span>{usuario?.direccion}</span>
                            </a>
                          </li>
                          <li class="list-item">
                            {/* <!-- customer phone --> */}
                            <a
                              class="link-normal small d-inline-grid gap-auto-2 text-muted"
                              href="javascript:void(0);"
                            >
                              <EventIcon fontSize="small" />
                              <span className="link-normal">
                                {usuario?.fechaNacimiento
                                  ? formatDateNoTime(usuario?.fechaNacimiento)
                                  : ""}
                              </span>
                            </a>
                          </li>
                          <li class="list-item">
                            {/* <!-- customer phone --> */}
                            <a
                              class="link-normal small d-inline-grid gap-auto-2"
                              href="javascript:void(0);"
                            >
                              <svg
                                class="text-muted"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                                ></path>
                              </svg>
                              <span className="fw-bold">
                                {usuario?.telefono}
                              </span>
                            </a>
                          </li>
                          <li class="list-item">
                            {/* <!-- customer phone --> */}
                            <a
                              class="link-normal small d-inline-grid gap-auto-2"
                              href="javascript:void(0);"
                              onClick={() =>
                                handleOpenModalClienteRequrimientos()
                              }
                            >
                              <span>Requerimientos especiales:</span>
                              <span>
                                <svg
                                  width="16px"
                                  height="16px"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                </svg>
                              </span>
                            </a>
                          </li>
                          <li class="list-item">
                            <span className="small d-inline-grid pt-2">
                              {usuario?.requerimientos
                                ? usuario.requerimientos
                                : "N/A"}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <!-- Modal : requerimientos --> */}
                    <Modal
                      status={openModalClienteRequerimientos}
                      handleStatus={handleOpenModalClienteRequrimientos}
                      headerText="Requerimientos del cliente"
                    >
                      <UsuarioRequeriments
                        usuarioDefault={usuario}
                        onSumit={handleCliente}
                        closeModal={handleOpenModalClienteRequrimientos}
                      />
                    </Modal>
                  </div>

                  <div class="card-body p-4 h-100">
                    {/* <!-- Shipping Address --> */}
                    <div class="mb-5">
                      <div class="fw-bold d-flex align-items-center mb-3">
                        <span class="w-100">Pagos</span>
                      </div>

                      <div class="small">
                        {pagos?.map((pag) => (
                          <>
                            <div class="d-flex justify-content-between">
                              <span
                                class="flex-none text-muted"
                                style={{ width: "120px" }}
                              >
                                <span class="badge bg-secondary"></span>
                                Monto
                              </span>
                              <span>{toMoney(pag.precio)}</span>
                            </div>
                            <a
                              class="link-muted small"
                              href="javascript:void(0)"
                            >
                              {formatDate(pag.fecha)} ${pag.metodopago}
                            </a>
                          </>
                        ))}
                      </div>
                    </div>

                    {/* <!-- Billing Address --> */}
                    <div class="mb-3">
                      <div class="fw-bold d-flex align-items-center mb-3">
                        <svg
                          class="flex-none text-muted me-2"
                          width="18px"
                          height="18px"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M4 3h16a2 2 0 0 1 2 2v6a10 10 0 0 1-10 10A10 10 0 0 1 2 11V5a2 2 0 0 1 2-2z"></path>
                          <polyline points="8 10 12 14 16 10"></polyline>
                        </svg>
                        <span class="w-100">ULTIMAS NOTAS</span>
                      </div>

                      <div class="d-flex justify-content-between mt-2">
                        <span class="d-block" style={{ width: "159px" }}>
                          NOTA
                        </span>
                        <span class="d-blockl">ESTADO ENTREGA</span>
                        <span class="d-blockl">ESTADO PAGO</span>
                      </div>
                      {latest.map((t) => (
                        <div class="d-flex justify-content-between mt-2">
                          <a
                            href="javascript:void(0);"
                            onClick={() =>
                              navigate(`/ticket/detalles/${t.uuid}`)
                            }
                            class="fw-medium d-block"
                            style={{ alignContent: "center" }}
                          >
                            {t.uuid}
                          </a>

                          <span class="rounded-sm p-2 small">
                            {t.estadoEntrega}
                          </span>

                          <span class="rounded-sm p-2 small">
                            {t.estadopago}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div class="card-footer p-4">
                    <span class="text-muted small">
                      {/* Order placed from: [US] United States */}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              status={openModalResponsable}
              handleStatus={handleOpenModalResponsable}
              headerText="Responsable"
            >
              <div className="d-flex justify-content-center">
                <i
                  class="fa fa-user"
                  aria-hidden="true"
                  style={{ fontSize: "10rem" }}
                ></i>
              </div>
              <BuscarUsuario
                onSumit={handleResponsableServicio}
                responsableServicio={responsableMovimiento}
                title="Seleccionar usuario"
                style={{
                  margin: "0em 1.2em 0",
                }}
              />
              <label
                style={{
                  margin: "0em 1.2em 0",
                }}
              >
                Contraseña
              </label>
              <input
                maxlength="18"
                autocapitalize="off"
                autocorrect="off"
                class="swal2-input"
                placeholder="Contraseña"
                type="password"
                style={{
                  margin: "0.5em 1em 0",
                  display: "flex",
                }}
                value={result}
                onChange={(event) => setResult(event.target.value)}
                onKeyDown={handleKeyDown}
              ></input>

              <div class="swal2-actions  mb-3">
                <div class="swal2-loader"></div>
                <button
                  type="button"
                  class="swal2-confirm swal2-styled swal2-default-outline"
                  aria-label=""
                  style={{
                    display: "inline-block",
                    backgroundColor: "rgb(155, 36, 44)",
                  }}
                  onClick={() => saveTicket()}
                >
                  Confirmar
                </button>
                <button
                  type="button"
                  class="swal2-cancel swal2-styled"
                  aria-label=""
                  style={{ display: "inline-block" }}
                  onClick={() => handleOpenModalResponsable()}
                >
                  Cancelar
                </button>
              </div>
            </Modal>
          </main>
          {/* /main */}
        </>
      )}
      <Modal
        status={openModalImprime}
        handleStatus={handleOpenModalImprime}
        headerText="Pago guardado"
      >
        <Grid container className="card-body" zIndex={1}>
          <Grid sm={6}>
            <a class="text-decoration-none" href="javascript:void(0)">
              <PrintIcon fontSize="small" />
              <PDFDownloadLink
                document={<DocumentPago pago={currentPago} />}
                fileName={`LET_ORDEN_${today.toLocaleDateString()}.pdf`}
                style={{ padding: "0.5rem" }}
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    "Cargando..."
                  ) : (
                    <a
                      className="text-decoration-none"
                      href="javascript:void(0);"
                      onClick={(event) => {
                        event.preventDefault();

                        handleOpenModalImprime();
                        impresionManual(url);
                      }}
                    >
                      <span style={{ marginInline: "0.5rem" }}>Imprime</span>
                    </a>
                  )
                }
              </PDFDownloadLink>
            </a>
          </Grid>
          <Grid sm={6}>
            <a
              class="text-decoration-none"
              href="javascript:void(0)"
              onClick={() => {
                onUpdate();
                handleOpenModalImprime();
              }}
            >
              <ArrowCircleRightIcon fontSize="small" />
              Continuar
            </a>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default TicketDetails;
